<template>
  <div class="kecheng">
    <img v-if="imgList.img" class="tui-img" :src="imgList.img" alt="">
    <div class="kmain">
      <kind-choose-view :show-school="true" :is-jinpin="false" :show-kc="false" :type="15" :schoole="schoole"
        :kind="kind" :zy-id="zyId" :if-img="false" @isOk="isOk" />
      <!-- 内容 -->
      <div class="kecent">
        <!-- <router-view /> -->
        <div v-for="(item, index) in list" :key="index" class="kecents" @click="imgxq(index, item)">
          <div v-if="item.isHavePrivateClass == 1" class="tag tagxbk">
            <img src="@/assets/img/smallban/tipss.png" alt="">
          </div>
          <img :src="item.img">
          <!-- <router-link :to="'/typeclassxq?id=' + item.id+'&tel='+form+'&uid='+uid+'&tenantid='+search.tenantid+'&type='+search.type+'&entrantsPrice='+item.entrantsPrice+'&jgPrice='+item.jgPrice"></router-link> -->
          <div class="kecentsCen">
            <div class="kecentsCenTop twoEllipsis">
              <div v-if="item.productLine == 2" class="k_cl">精品</div>
              <div class="k_til">
                <!-- <span v-if="item.isHavePrivateClass==1" class="xbk"> <img
                    src="~@/assets/img/typeclass/z2.png" alt=""></span> -->
                {{ item.tiitle }}
              </div>
            </div>
            <div v-if="item.curForms && item.curForms.length > 0" class="kecentsCenCen">
              <span v-for="(curForms, curindex) in item.curForms" :key="curindex" class="curForms">
                {{ curindex != 0 ? '·' : '' }}{{ curForms }} </span>| {{ item.num }}门课程
            </div>
            <div class="kecentsCenBtm">
              <div class="w-b-100">
                <span class="price"><span class="tips">¥ </span>{{ item.price }}</span>
                <!-- <span class="oldPrice"><span class="tips">¥ </span>{{ item.olderPrice }}</span> -->
              </div>
              <div class="more dis_flex">
                <!-- <el-image c src="@/assets/img/Home/Frame.png" fit="contain"> </el-image> -->
                <img class="img" src="@/assets/img/Home/Frame.png" object-fit="contain">
                {{ item.clicks ? item.clicks : 0 }}人气值
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 暂无课程 -->
    <div v-if="!list || list.length == 0" class="nothing">
      <!-- <img class="nothing-img" src="@/assets/img/study/noLearn.png" /> -->
      <div class="nothing-text">暂无内容哦~</div>
    </div>
    <div style="
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30%;
        margin: 40px auto;
        margin-bottom: 80px;
      ">
      <el-pagination class="pagination" :total="total" :current-page="pageNum" :page-sizes="[12, 24, 28, 32]"
        :page-size="pageSize" :pager-count="5" layout="total, sizes, prev, pager, next, jumper"
        @current-change="handleCurrentChange" @size-change="handleSizeChange" />
    </div>
  </div>
</template>
<script>
import { selectPageListToIndex } from '@/api/home'
import KindChooseView from '../../components/kindChooseViewktypevip.vue'
import { selectKindAllCollage, getSurfaceList } from '@/api/home'
import { getDomain } from '@/api/cookies'
export default {
  components: { KindChooseView },

  data() {
    return {
      imgList: {},
      list: {},
      search: {},
      pageSize: 12,
      pageNum: 1,
      total: 0,
      kind: null,
      zyId: null,
      schoole: null,
      domain: null,
      loading: null
    }
  },
  async beforeDestroy() {
    this.loading.close()
  },
  async created() {
    this.domain = getDomain()
    getSurfaceList(4, undefined, this.domain.entrantsType).then((res) => {
      if (res.code == 0) {
        if (res.msg.length > 0) {
          this.imgList = res.msg[0]
        } else {
          this.imgList = {}
        }
      }
    })
    if (this.$route.query.kind) {
      this.kind = await Number(this.$route.query.kind)
      this.search.kind = await Number(this.$route.query.kind)
    }
    if (this.$route.query.zId) {
      this.zyId = await Number(this.$route.query.zId)
      this.search.zyId = await Number(this.$route.query.zId)
    }
    if (this.$route.query.schoole) {
      this.schoole = await Number(this.$route.query.schoole)
      this.search.school = await Number(this.$route.query.schoole)
    }
    await this.getList()
  },
  methods: {
    // 获取知识套餐列表
    getList() {
      this.loading = this.$loading({
        lock: true,
        text: '加载中',
        spinner: '',
        background: 'rgba(0, 0, 0, 0.2)'
      })
      selectPageListToIndex(
        this.pageSize,
        this.pageNum,
        this.search.school,
        this.search.kind,
        this.search.zyId,
        this.search.type,
        this.search.productLine,
        this.search.areaId == 1 ? undefined : this.search.areaId,
        this.search.name ? this.search.name : undefined
      ).then((res) => {
        for (const item of res.rows) {
          if (item.curForms) {
            item.curForms = item.curForms.split(',')
          }
        }
        this.list = res.rows
        this.total = res.total
        this.loading.close()
      }).catch(() => {
        this.loading.close()
      })
    },

    handleSizeChange(val) {
      this.pageSize = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.getList()
    },
    // 筛选
    isOk(val) {
      this.search = val
      this.pageNum = 1
      this.getList()
    },

    // 点击图片跳转到详情页
    imgxq(index) {
      /* this.$router.push({
        name: "知识套餐课详情",
        query: {
          id: this.list[index].id,
          tenantid: this.search.tenantid,
          type: this.search.type,
          entrantsPrice: this.list[index].entrantsPrice,
          jgPrice: this.list[index].jgPrice,
        },
      }); */
      const routeUrl = this.$router.resolve({
        name: '知识套餐课详情',
        query: {
          id: this.list[index].id,
          tenantid: this.search.tenantid,
          type: this.search.type,
          entrantsPrice: this.list[index].entrantsPrice,
          jgPrice: this.list[index].jgPrice
        }
      })
      window.open(routeUrl.href, '_blank')
    }
  }
}
</script>
<style lang="less" scoped>
.tui-img {
  margin: 0;
  width: 100%;
}

/deep/ .kmain {
  width: 1220px;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  margin: 24px auto;
}

/deep/ .box-div {
  border-radius: 8px 8px 8px 8px;
}

/deep/ .zychoice {
  // height: 82px;
  // line-height: 82px;
  border-radius: 8px 8px 8px 8px;
  padding-left: 32px;
}

.kecheng {
  width: 100%;
  height: 100%;
  background: #f5f7fa;
  overflow: hidden;

  // 内容
  .kecent {
    width: calc(1220px + 24px);
    height: auto;
    margin: 30px 0px 0px calc(50% - 610px);
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    background: #f5f7fa;

    .kecents {
      width: 287px;
      background: #ffffff;
      box-shadow: 0px 2px 4px 0px rgba(237, 239, 242, 0.7);
      cursor: pointer;
      cursor: pointer;
      margin: 0px 24px 24px 0px;
      border-radius: 8px 8px 8px 8px;
      // overflow: hidden;
      position: relative;

      img {
        width: 100%;
        height: 161px;
        cursor: pointer;
      }

      .boutique {
        width: 100%;
        margin: auto;
        height: 158px;
        position: relative;
        padding: 12px 16px 16px;
        background-image: url("../../assets/img/Home/boutiqueBan.png");
        background-size: 100% 100%;

        .kecentsCenTop {
          color: #5a4629 !important;
        }

        .more {
          color: #5a4629 !important;
          font-size: 12px !important;
        }
      }

      .kecentsCen {
        width: 100%;
        margin: auto;
        height: 150px;
        position: relative;
        padding: 12px 16px 16px;

        .kecentsCenTop {
          /*  font-size: 18px;
          font-family: Microsoft YaHei-Regular;
          font-weight: 400;
          color: #333333;
          line-height: 26px; */
          font-size: 16px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          line-height: 24px;
          height: 48px;
          display: flex;

          div {
            width: 36px;
            height: 20px;
            background: linear-gradient(132deg, #ff9051 0%, #ff5e51 100%);
            border-radius: 3px 3px 3px 3px;
            opacity: 1;
            line-height: 20px;
            text-align: center;
            margin-right: 8px;
            margin-top: 2px;

            font-size: 12px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
          }

          .k_cl {
            margin-top: 5px;
          }

          .k_til {
            width: 254px;
            height: 48px;
            font-size: 16px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            line-height: 24px;
            overflow: hidden;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            background: none;
            text-align: left;
          }
        }

        .kecentsCenCen {
          margin-top: 12px;
          // height: 20px;
          // background-color: #fff2e9;
          border-radius: 2px 2px 2px 2px;
          display: inline-block;
          opacity: 1;
          // padding: 0px 8px;
          // line-height: 20px;
          font-size: 12px;
          font-family: Microsoft YaHei-Regular;
          font-weight: 400;
          color: #999999;
        }

        .boutiqueTxt {
          margin-top: 12px;
          width: 255px;
          height: 26px;
          display: inline-block;
          padding: 0px 8px;
          line-height: 26px;
          font-size: 12px;
          background: none;
          background-image: url("../../assets/img/Home/boutiqueTxt.png") !important;
          background-size: 100% 100%;
          color: #a57800;
          // padding-left: 83px;
        }

        .kecentsCenBtm {
          position: absolute;
          bottom: 18px;
          left: 16px;
          right: 16px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .boutiquePrice {
            color: #5a4629 !important;
          }

          .price {
            font-size: 16px;
            font-family: Microsoft YaHei-Regular;
            font-weight: bold;
            color: #ff5e51;
            line-height: 18px;

            .tips {
              font-size: 14px;
              font-weight: 400;
              line-height: 14px;
            }
          }

          .oldPrice {
            font-size: 16px;
            font-family: Microsoft YaHei-Regular;
            font-weight: 400;
            color: #999999;
            line-height: 16px;
            text-decoration: line-through;
            margin-left: 8px;
          }

          .more {
            font-size: 12px;
            font-family: Microsoft YaHei-Regular;
            font-weight: 400;
            color: #999999;
            line-height: 16px;
            margin-left: 8px;
            flex-shrink: 0;
            align-items: center;

            .img {
              width: 13px;
              height: 13px;
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
}

.isNull {
  width: 1200px;
  margin: 0px auto;
}

// .curForms {
//   background: #ffffff;
// }
/deep/ .el-pagination .active {
  background: #1061ff !important;
}

/deep/ .on {
  background: #ecf2ff !important;
  color: #1061ff !important;
}

.xbk {
  img {
    width: 60px !important;
    height: 22px !important;
    display: inline !important;
    margin-right: 8px !important;
    position: relative;
    top: 5px;
  }
}

.tagxbk {
  width: 45px;
  height: 21px;
  line-height: 21px;
  border-radius: 8px 0 8px 0;
  // background-image: url("~@/assets/img/smallban/bac.png");
  background-size: 45px 21px;
  color: #ffffff;
  text-align: center;
  font-size: 11px;
  position: absolute;
  top: 0;
  left: 0;

  img {
    transform: translateY(-8px);
    width: 60px !important;
    height: auto !important;
  }
}
</style>
